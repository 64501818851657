import * as React from 'react';
import './SiteHeader.scss';
import { useLocation } from '@reach/router';
import LanguageSelector from './LanguageSelector';
import Navbar from './Navbar';
import navigation from '../constants/navigation.js';
import languages from '../constants/languages';
import logo from '../images/lookup-evergreen-logo.svg';
import LangIcon from "../images/translate-icon.svg"

function SiteHeader() {
  const location = useLocation();
  const currentPathLanguage = location.pathname.split('/')[1];

  const DropdownText = {
    'en': 'Select a Location',
    'es': 'SELECCIONA UN LUGAR',
  };

  // const NocoCityText = {
  //   'en': 'Northern Colorado',
  //   'es': 'Northern Colorado',
  // };

  // const NocoCityLink = {
  //   'en': '/en/noco/',
  //   'es': '/es/noco/',
  // };

  const FountainCityText = {
    'en': 'Fountain Hills, AZ',
    'es': 'Fountain Hills, AZ',
  };

  const FountainCityLink = {
    'en': '/en/fountain-hills/',
    'es': '/es/fountain-hills/',
  };

  return (
    <>
    <div className="top-bar columns">
        <div className='column language-bar'>
          <div>{ location.pathname.split('/')[1] === 'en' ? (
          <a href="/es/"><img src={LangIcon} alt="language icon" />&nbsp;- Español</a>
            ) : (
              <a href="/en/"><img src={LangIcon} alt="language icon" />&nbsp;- English</a>
            )
          }
          </div>
        </div>
      </div>
      <div className="navigation" id="evergreen">
      <header className="main-header" lang={currentPathLanguage}>
        <div>
          <a href="/" className="logo" id="desktop-logo">
            <img src={logo} alt="Look Up Celebration with Will Graham" />
          </a>
          <div class="sec-center" id="desktop-dropdown"> 	
            <input class="dropdown" type="checkbox" id="dropdown" name="dropdown"/>
            <label class="for-dropdown" for="dropdown">{DropdownText[currentPathLanguage]} <i class="uil uil-arrow-down"></i></label>
            <div class="section-dropdown"> 
              <a href={FountainCityLink[currentPathLanguage]}>{FountainCityText[currentPathLanguage]} <i class="uil uil-arrow-right"></i></a>
            </div>
          </div>
        </div>
        {currentPathLanguage && navigation.primary[currentPathLanguage] && (
          <LanguageSelector options={languages} current={'es'} />
        )}
      </header>
      <div>
          {currentPathLanguage && navigation.primary[currentPathLanguage] && (
            <Navbar items={navigation.primary[currentPathLanguage]}></Navbar>
          )}
        </div>
    </div>
    </>
  );
}

export default SiteHeader;
