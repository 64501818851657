import React from 'react';
import { useLocation } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LangIcon from "../images/translate-icon.svg"

function LanguageSelector({ options, current }) {
  const location = useLocation();
  const currentPathLanguage = location.pathname.split('/')[1];
  current = currentPathLanguage;
  return (
    <div className="dropdown is-hoverable">
      <div className="dropdown-trigger">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu6"
          id="lang-button"
        >
          <span className="icon">
            <img src={LangIcon} alt=" " />
          </span>
          <span>
            {' '}
            {' - '}
            {options[current].labels[current]}
          </span>
          <span className="icon is-small">
            <FontAwesomeIcon icon="angle-down" />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu6" role="menu">
        <div className="dropdown-content">
          {Object.keys(options).map((lang) => {
            if (lang !== current) {
              return (
                <div className="dropdown-item">
                  <a href={`/${lang}`}>{options[lang].labels[current]}</a>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default LanguageSelector;
